<template>
  <div :class="origin === 'flow' ? 'flow row' : 'flow row bg-inherit removeBg !pt-[0px]'
    " data-cy="children_wrapper_div">
    <div class="clearfix tempHiddenPage" :class="{
      showPage: showPage,
      noSidePadding: $route.name === 'myChildren',
      block: $route.name === 'myChildren',
      addTopPadding: origin !== 'flow',
    }" style="border: none; min-height: 100%; padding-bottom: 40px">
      <div v-if="term" class="termtext col-xs-12" :class="getTermClass" data-cy="new_term_message">
        Are these details correct for your Children ?
      </div>
      <div class="col-xs-12">
        <Headerv2 title="My Children" :showBack="origin == 'flow' ? false : true" data-cy="header_my_children" />
      </div>
      <div class="col-xs-12" v-if="childrenNotAvailable == false">
        <div class="alert-mira lightGreen">
          <p>
            <b>Add your children to your profile.</b>
            <br />You must have at least 1 child at a school registered with us,
            or be a staff member at the school to join its community.
          </p>
        </div>
      </div>
      <div v-if="childrenNotAvailable == true" class="col-xs-12" data-cy="children_not_available">
        <div class="mialert lightRed">
          <p>
            Only users with the parent role can add children to their accounts.
            To add roles to your account, enter a parent or staff school code in
            the 'My Schools' page.
          </p>
        </div>
      </div>
      <div class="col-xs-12 form-row" v-if="!childrenNotAvailable" data-cy="children_available">
        <div>
          <transition-group name="fade" tag="div">
            <div :key="child_row.id" v-for="(child_row, $index) in children" class="child-row border-bottom"
              :data-cy="'child_row_index' + $index">
              <div class="child-row__item" v-if="!child_row.edit">
                <div class="avatar-col">
                  <img class="smallAvatar" :src="s3url + child_row.profile_photo" :alt="child_row.profile_photo" />
                </div>
                <div class="middle-col">
                  <span>
                    <b class="font-black student-name">{{
                      child_row.first_name
                      }}</b>
                  </span>
                  <br />
                  <b v-if="child_row.current_year != 99">
                    <span class="student-year">{{
                      getStudentYear(
                        child_row.organization_id,
                        child_row.current_year
                      )
                    }}</span>
                  </b>
                  <br />
                  <span class="student-school" :class="{
                    colorRed:
                      getSchoolName(child_row.organization_id) ==
                      'Unregistered School',
                  }">
                    {{
                      getSchoolName(child_row.organization_id)
                    }}
                  </span>
                  <!-- <div>
                    <span class="parentColor">
                      {{ getSenLabel(child_row.sen) }}
                    </span>
                  </div> -->
                  <div v-if="child_row.parents.length > 0" style="color: #bbb">
                    Shared with: {{ child_row.parents[0] }}
                  </div>
                </div>
                <div class="text-right">
                  <van-button @click="editChild($index)" data-cy="edit_child_btn" type="info" size="mini"
                    class="btn-black pull-right ml-2"><i class="far fa-pencil pr-1"></i>Edit</van-button>
                </div>
              </div>
            </div>
          </transition-group>
          <div>
            <van-button type="default" size="small" plain @click="addChild()" class="add-child-btn"
              data-cy="add_another_child_btn">
              <i class="fa fa-plus parentBg !text-white text-sm w-5 h-5 inline-block rounded-full p-0.5 mr-1.5"></i>
              Add another child
            </van-button>
          </div>
        </div>
      </div>
      <div class="col-xs-12 form-action-row mb-0">
        <button class="btn greyBtn van-button van-button--info van-button--normal van-button--plain"
          @click="$router.go(-1)">
          Go Back
        </button>
        <button class="btn btn-black" @click="continueAction" data-cy="continue_action"
          :disabled="loading || disableContinue">
          Continue
        </button>
      </div>
      <div>
        <ChildModalFlow :child="child_row" :show-errors="showErrors" :schools="schools"
          :school-years="getSchoolYears(child_row.organization_id)" :s3url="s3url" :sen-options="senOptions"
          @hide-modal="confirmCancel" @change-picture="changePicture" @confirm-remove="confirmRemove"
          @confirm-cancel="confirmCancel" @save-child="saveChildValidation" data-cy="child_modal_flow" />
      </div>
      <!-- Modal for selecting avatar -->
      <modal class="modal-flow" name="selectAvatar" data-cy="select_child_avatar_modal" id="modalOverlay"
        :adaptive="true" height="auto">
        <ModalHeader :flow="true" title="Select your Avatar" icon="fa fa-paw" @hideModal="hideModal('selectAvatar')"
          data-cy="close_select_child_avatar_modal" />
        <div>
          <div class="section blue text-center" data-cy="select_avatar_image_wrapper">
            <img :key="index" v-for="(n, index) in avatars" @click="
              selectAvatar(n);
            closeModal('selectAvatar');
            " class="avatarImage" :class="{ active: currentAvatarId == n }"
              :src="avatars_url + (n < 10 ? '0' + n : n) + '.png'" alt />
          </div>
          <div class="row text-center padding30" style="margin-bottom: 10px">
            <div class="col-md-12">
              <button class="btn btn-black" type="info" @click="closeModal('selectAvatar')" data-cy="select_avatar_btn">
                Continue
              </button>
            </div>
          </div>
        </div>
      </modal>
      <modal name="duplicateStudent" :adaptive="false" :height="600" :scrollable="true" style="overflow: scroll"
        data-cy="duplicate_student_modal">
        <ModalHeader title="Child already exists" icon="fa fa-paw" @hideModal="closeModal('selectAvatar')"
          data-cy="close_duplicate_student_modal" />
        <p class="alert alert-warning" style="
            background: #fcf8e3;
            padding: 10px;
            margin: 20px;
            border-radius: 25px;
          ">
          {{ duplicateMessage }}
          <br />
          <br />
          {{ duplicateChildParent }} can add {{ duplicateChild }} to your
          profile by using the 'Invite your Partner' function within their app.
          Please do not create a duplicate child profile unless necessary.
        </p>
        <hr />
        <div class="row text-center padding30">
          <div class="col-xs-12">
            <van-button type="default" name="button" size="small" @click="
              closeModal('selectAvatar');
            forceCreation = true;
            submit();
            " data-cy="create_anyway_duplicate_student_modal">Create new child profile anyway</van-button>
          </div>
        </div>
        <div class="row text-center" style="margin-top: 10px">
          <div class="col-xs-12">
            <van-button type="default" name="button" size="small" @click="
              removeDuplicate();
            closeModal('selectAvatar');
            " data-cy="remove_duplicate_btn">Remove duplicate and wait for invite</van-button>
          </div>
        </div>
      </modal>
      <div style="clear: both"></div>
      <button class="blankButton" v-if="
        origin == 'flow' &&
        children.length === 0 &&
        childrenNotAvailable == false &&
        subAcc &&
        subAcc.is_staff == 0
      " style="color: red" @click="removeParentSubAccount">
        I do not have children at the school
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MyChildrenConstants from "./logic/myChildren";
import ApiService from "@/services/ApiService";
import ModalHeader from "@/components/ModalHeader";
import ChildModalFlow from "@/components/modals/ChildModalFlow";
import Headerv2 from "@/components/Headerv2.vue";
export default {
  name: "myChildren",
  components: {
    ChildModalFlow,
    ModalHeader,
    Headerv2,
  },
  props: {
    origin: String,
  },
  data() {
    return {
      progress: 65,
      childHasBothParents: [],
      addressSelection: [],
      selectedAvatar: {},
      avatars_url: MyChildrenConstants.avatars_url,
      s3url: MyChildrenConstants.s3url,
      avatars: MyChildrenConstants.avatars,
      alphabet: MyChildrenConstants.alphabet,
      showModal: false,
      showErrors: false,
      pendingChild: 0,
      loading: true,
      forceCreation: false,
      currentAvatarId: 0,
      showPage: true,
      // mainUser : store.get('user'), //TODO
      // subUser : store.get('subAccount'), //TODO
      childrenNotAvailable: false,
      schools: [],
      children: [],
      pendingChildchildren: [],
      showAvatarModal: false,
      duplicateChildModal: false,
      confirmModal: false,
      isModalClosing: false,
      duplicateMessage: "",
      duplicateChild: "",
      duplicateChildParent: "",
      editing: false,
      hasApartner: false,
      senOptions: [
        { value: "", name: "Please select" },
        { value: 0, name: "No travel support" },
        { value: 1, name: "Public Transport Bus/Train Pass" },
        { value: 2, name: "Shared transport (minibus/coach/transport)" },
        { value: 3, name: "Single taxi with guide escort" },
        { value: 4, name: "Single taxi only" },
        { value: 5, name: "Personal travel budget" },
        { value: 6, name: "Mileage payments" },
      ],
      child_row: {},
      showContinue: true,
      // term: "summer" // TODO make dynamic
    };
  },
  computed: {
    term: function () {
      return this.$store.state.general.term;
    },
    getTermClass() {
      return "termcolor_" + this.term;
    },
    subAcc() {
      return this.$store.state.general.subAccount;
    },
    disableContinue() {
      return this.children.length === 0 && this.childrenNotAvailable == false;
    },
    yearLabels() {
      return this.$store.state.general.yearLabels;
    },
  },
  methods: {
    getChildren() {
      this.$store.dispatch("loading", true);
      ApiService.userApi
        .getParentSchools("parentOnly=true&mis_schools=true&partner=true")
        .then((response) => {
          this.$store.dispatch("loading", false);
          if (response.data == "No Schools") {
            this.$notify({
              message: "You have no schools",
              type: "warning",
            });
          } else {
            this.schools = response.data.schools;
            this.children = response.data.children;
            this.partners = response.data.partner;

            if (this.children.length == 0 && !this.childrenNotAvailable) {
              this.addChild();
            }
          }
        });
    },
    changePicture() {
      this.currentAvatarId = this.child_row.pendingImage;
      this.$modal.show("selectAvatar");
      this.$forceUpdate();
    },
    hideModal(modalName) {
      this.$modal.hide(modalName);
    },
    addChild() {
      this.child_row = {
        first_name: "",
        avatar: "",
        year: "",
        current_year: null,
        organization_id: this.schools.length === 1 ? this.schools[0].id : "",
        sen: 0,
      };
      this.$modal.show("editModal");
      this.editing = true;
    },
    editChild($index) {
      this.child_row = this.children[$index];
      this.editing = true;
      this.$modal.show("editModal");
      this.$forceUpdate();
    },
    getSchoolName(schoolId) {
      if (schoolId == 0) {
        return 'Graduated';
      }
      var school = this.getSchoolObj(schoolId);

      if (school.length == 0) {
        return "Unregistered School";
      } else {
        return school[0].name;
      }
    },
    getSenLabel(value) {
      const option = this.senOptions.filter((itm) => value === itm.value);
      if (option.length > 0) {
        return option[0].name;
      } else {
        return "";
      }
    },
    getSchoolObj(schoolId) {
      return this.schools.filter(function (school) {
        return parseInt(school.id) == parseInt(schoolId);
      });
    },

    getSchoolYears(schoolId) {
      if (!schoolId) {
        return [];
      }
      const school = this.schools.filter(function (school) {
        return school.id == schoolId;
      });
      if (school.length > 0) {
        return school[0].years;
      } else {
        return [];
      }
    },

    removeChild(childIndex) {
      var _id = this.children[childIndex].id;

      if (_id) {
        this.childDeletion(childIndex, _id);
      } else {
        this.children.splice(childIndex, 1);
      }
    },
    confirmRemove() {
      this.$dialog
        .confirm({
          title: "Confirm",
          message:
            "Are you sure you want to delete '" +
            this.child_row.first_name +
            "' ?",
          confirmButtonColor: "red",
        })
        .then(() => {
          if (this.child_row.id) {
            this.loading = true;
            ApiService.userApi.removeChild(this.child_row.id).then(() => {
              const index = this.children.findIndex((v) => {
                return v.id === this.child_row.id;
              });
              this.children.splice(index, 1);
              this.loading = false;
              this.closeModal("editModal");
            });
          } else {
            this.closeModal("editModal");
          }
        });
    },
    confirmCancel() {
      this.$dialog
        .confirm({
          title: "Confirm",
          message: "Close without saving?",
          confirmButtonText: "Yes",
          // confirmButtonColor: "red"
        })
        .then(() => {
          this.cancelEditing();
        });
    },
    cancelEditing() {
      this.child_row.edit = false;
      this.closeModal("editModal");
      this.$forceUpdate();
    },

    childDeletion(index, childId) {
      this.$modal.show("dialog", {
        title: "Alert!",
        text:
          "You are about to delete <strong>" +
          this.children[index].first_name +
          "</strong>",
        buttons: [
          {
            title: "DELETE",
            class: "vue-dialog-button deleteButton",
            handler: () => {
              this.deletionConfirmation();
            },
          },
          {
            title: "Cancel",
          },
        ],
      });
      this.childSelection = childId;
      this.childIndexSelection = index;
      // this.confirmModal = true;
      this.pendingChild = index;
    },

    deletionConfirmation() {
      this.loading = true;
      ApiService.userApi.removeChild(this.childSelection).then(() => {
        this.children.splice(this.childIndexSelection, 1);
        this.loading = false;
        // this.$forceUpdate();
      });
      this.$modal.hide("dialog");
    },

    removeDuplicate() {
      this.children = this.children.filter((child) => {
        return child.first_name != this.duplicateChild;
      });
    },
    validateChildEntry() {
      this.showErrors = true;
      var hasError = false;
      if (
        this.child_row.first_name == "" ||
        !this.child_row.last_name ||
        this.child_row.current_year === "" ||
        this.child_row.current_year === null ||
        typeof this.child_row.current_year == "undefined" ||
        // (this.child_row.current_year == "" &&
        //   this.child_row.year == "") ||
        !this.child_row.organization_id ||
        (!this.child_row.pendingImage && !this.child_row.profile_photo)
      ) {
        hasError = true;
      }
      if (this.child_row.sen === "") {
        hasError = true;
      }

      return hasError;
    },
    saveChildValidation() {
      if (this.validateChildEntry()) {
        this.$notify({
          message: "Ensure all fields are filled in",
          type: "danger",
        });
        return;
      }
      if (this.partners.length > 0 && !this.child_row.id) {
        this.$dialog
          .confirm({
            title: "Confirm",
            message:
              "Add child to " +
              this.partners.map((i) => i.first_name + " ") +
              "profile too?",
            cancelButtonText: "No",
            confirmButtonText: "Yes",
          })
          .then(() => {
            this.child_row.addToPartner = true;
            this.saveChild();
          })
          .catch(() => {
            this.child_row.addToPartner = null;
            this.saveChild();
          });
      } else {
        this.saveChild();
      }
    },
    saveChild() {
      this.loading = true;
      this.$store.dispatch("loading", true);
      ApiService.userApi
        .saveNewChild(this.child_row)
        .then(async (response) => {
          if (response.status == 200) {
            this.$notify({
              message: "Saved",
              type: "success",
            });
            this.closeModal("editModal");
            this.getChildren();
            if (response?.data.refreshSchools) {
              const result = await ApiService.userApi.getUserInfo();
              this.$store.dispatch("updateUserInfo", result.data);
            }
          }
          this.loading = false;
          this.showErrors = false;
        })
        .catch((err) => {
          this.loading = false;
          if (
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.duplicate
          ) {
            this.$dialog
              .confirm({
                title: "Confirm",
                message: err.response.data.error.message,
                cancelButtonText: "Wait for invite",
                confirmButtonText: "Duplicate child",
              })
              .then(() => {
                this.child_row.forceCreation = true;
                this.saveChild();
              })
              .catch(() => {
                this.closeModal("editModal");
                // this.child_row = {};
              });
          } else {
            alert("Network error. Please try later");
          }
        })
        .finally(() => this.$store.dispatch("loading", false));
    },
    submit() {
      if (this.childrenNotAvailable) {
        this.$router.push("journeys");
        return;
      } else {
        const hasNewEntries = this.children.filter(
          (item) => isNaN(item.id) == true
        ).length;
        if (hasNewEntries > 0 && this.hasApartner) {
          this.$dialog
            .confirm({
              title: "Confirm",
              message: "Would you like to add it to your partner too?",
            })
            .then(() => {
              alert("yeap");
            })
            .catch(() => {
              alert("nope");
            });
        }
      }
    },
    proceedSubmit() {
      this.showErrors = true;

      var postData = this.preparePostData();
      if (postData == "error") {
        if (this.children.length == 0) {
          this.$notify({
            message:
              "You must have at least one child on your account to continue",
            type: "warning",
          });
        } else {
          this.$notify({
            message: "Please fill in the empty fields to continue",
            type: "warning",
          });
        }
        return;

        // empty array, no children has been added
      } else if (postData == false) {
        if (this.$router.currentRoute.name == "flowChildren") {
          this.$emit("redirect", "journeys");
          return true;
        }
      }

      this.loading = true;

      ApiService.userApi
        .saveNewChildren({
          children: postData,
          forceCreation: this.forceCreation,
        })
        .then((response) => {
          if (response.status == 200) {
            // store.set('lastState', 'myJourneys');
            this.editing = false;
            if (this.$router.currentRoute.name == "flowChildren") {
              ApiService.userApi.accountStatusRedirect(false, true);
            } else {
              this.$notify({
                message: "Saved",
                type: "success",
              });
              this.getChildren();
              ApiService.userApi.accountStatusRedirect(false, true);
            }
          } else if (response.status == 400) {
            this.duplicateMessage = response.data.message;
            this.duplicateChild = response.data.child_name;
            this.$modal.show("duplicateStudent");
            // this.duplicateChildModal = true;
          }
          this.loading = false;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            console.log(err.response.data.error);
            this.duplicateMessage = err.response.data.error.message;
            this.duplicateChild = err.response.data.error.child_name;
            this.$modal.show("duplicateStudent");
            this.duplicateChildParent = err.response.data.error.parent_name;
          }
          this.loading = false;
        });
    },
    saveChildren() {
      this.loading = true;
      var childrenSelection = this.getChildrenSelection();
      ApiService.userApi
        .saveNewChildren(childrenSelection)
        .then((response) => {
          if (response.status == 200) {
            if (this.$router.currentRoute.name === "flowChildren") {
              this.$emit("redirect", "journeys");
            } else {
              this.$notify({
                message: "Saved",
                type: "success",
              });
            }
          }
          this.loading = false;
        })
        .catch(function (response) {
          alert(response);
        });
    },
    closeModal(modalName) {
      this.$modal.hide(modalName);
    },
    selectAvatar(id) {
      this.child_row.pendingImage = id;
      this.child_row.avatar = id;
      this.child_row.imageUrl = this.avatars_url + id + ".png";
      this.currentAvatarId = id;
      setTimeout(() => {
        this.$forceUpdate();
      }, 200);
      this.hideModal("selectAvatar");
    },

    openModal() {
      this.showModal = true;
    },

    continueAction() {
      if (this.$router.currentRoute.name == "flowChildren") {
        if (this.disableContinue) {
          return;
        }
        if (this.children.filter((i) => i.organization_id == 0 && i.current_year != 99).length > 0) {
          this.$dialog
            .confirm({
              title: "Alert",
              message:
                "You have a child not registered to a school. Select a school for them or your profile may be removed.",
              cancelButtonText: "Back",
              confirmButtonText: "Ignore and continue",
            })
            .then(() => {
              ApiService.userApi.syncMultiSchools().finally(() => {
                this.$emit("redirect", "journeys");
              })
            });
          return true;
        } else {
          ApiService.userApi.syncMultiSchools().finally(() => {
            this.$emit("redirect", "journeys");
          })
        }
        return true;
      } else {
        ApiService.userApi.syncMultiSchools().finally(() => {
          ApiService.userApi.accountStatusRedirect(true, true);
        })
      }
    },

    preparePostData() {
      var returnData = [];
      var currentObj = {};
      var hasChildren = false;
      this.showErrors = true;
      var error = false;

      for (var y = 0; y < this.children.length; y++) {
        var currentChild = this.children[y];

        if (currentChild.id && !currentChild.edit) {
          hasChildren = true;
          continue;
        }
        if (
          currentChild.first_name == "" ||
          !currentChild.last_name ||
          currentChild.current_year === "" ||
          typeof currentChild.current_year == "undefined" ||
          // (currentChild.current_year == "" &&
          //   currentChild.year == "") ||
          !currentChild.organization_id ||
          (currentChild.sen != 0 && !currentChild.sen) ||
          (!currentChild.pendingImage && !currentChild.profile_photo)
        ) {
          // if(currentChild.edit == true){
          error = "error";
          // }
        }

        // if(this.schools[i].current_children.length > 0){
        //   hasChildren = true;
        // }
        currentObj = {
          first_name: currentChild.first_name,
          last_name: currentChild.last_name,
          avatar: currentChild.pendingImage,
          year: currentChild.current_year,
          organization_id: currentChild.organization_id,
        };

        if (currentChild.id) {
          currentObj.id = currentChild.id;
        }
        returnData.push(currentObj);
      }

      if ((hasChildren == false && returnData == false) || error) {
        return "error";
      }

      return returnData;
    },
    removeParentSubAccount() {
      ApiService.userApi.removeParentSubAccount().then((response) => {
        if (response.data == "refresh") {
          ApiService.userApi.getUserInfo().then((response) => {
            this.$store.dispatch("updateUserInfo", response.data);
            setTimeout(() => {
              this.$router.push({ name: "journeys" });
            }, 300);
          });
          // window.location.reload(true);
        }
      });
    },
    checkIfChildrenAvailable() {
      if (
        this.$store.state.general.user &&
        this.$store.state.general.user.not_a_parent == 1 &&
        (!this.$store.state.general.subAccount ||
          this.$store.state.general.subAccount.not_a_parent == 1)
      ) {
        this.childrenNotAvailable = true;
      } else {
        this.childrenNotAvailable = false;
      }
    },
    getStudentYear(schoolId, currentYear) {
      if (
        this.yearLabels[schoolId] &&
        this.yearLabels[schoolId].hasOwnProperty(currentYear)
      ) {
        let yearLabel = this.yearLabels[schoolId][currentYear];
        if (isNaN(yearLabel)) {
          return yearLabel;
        } else {
          return "Year " + yearLabel;
        }
      } else {
        return "Year " + currentYear;
      }
    },
  },
  created() {
    setTimeout(() => {
      this.checkIfChildrenAvailable();
    }, 70);
    // setTimeout(function() {
    //   $window.scrollTo(0, 0);
    // }, 300);

    setTimeout(() => {
      this.showPage = true;
      this.loading = false;
    }, 700);

    // if (!$rootScope.term || $rootScope.term == undefined) {
    //   // $rootScope.term = store.get("term");
    // }
    this.getChildren();
    //REDIRECTION IF NEEDED
    if (this.$store.state.general.registrationInProgress) {
      //REDIRECTION IF NEEDED
      ApiService.generalApi.accountStatusRedirect();
    } else {
      this.hideTopBar = true;
    }
  },
  watch: {
    children: function () { },
    "$store.state.general.subAccount": function () {
      this.checkIfChildrenAvailable();
    },
  },
};
</script>
<style lang="scss" scoped>
.formHeader {
  line-height: 30px;
  text-align: left;
  margin-bottom: 10px;
  border-bottom: 1px solid #f9f9f9;
}

.formHeader button {
  line-height: 15px;
}

.colorRed {
  color: red;
}

.EDIT_FORM {
  background: #f9f9f9;
  padding-top: 8px;
  border: 1px solid #cccccc;
  padding-bottom: 15px;
  /* margin-top: 15px; */
}

.avatarImage {
  width: 60px;
  height: 60px;
  margin: 4px;
}

.avatarButton {
  position: relative;
  margin-top: 10px;
}

.avatarImage.active {
  border: 1px solid red;
  border-radius: 30px;
}

.emptyProfileText {
  position: absolute;
  z-index: 9;
  top: 26px;
  left: 61px;
  /* font-weight: bold; */
  line-height: 14px;
}

#mainContent.noSidePadding {
  margin-left: -15px;
  margin-right: -15px;
}

.add-child-btn {
  display: flex;
  align-items: center;
  float: left;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  border: 0;

  i {
    margin-right: 5px;
  }
}

.addTopPadding {
  padding-top: 10px;
  margin-top: 0px;
}

.child-row {
  margin: 0;

  &__item {
    width: 100%;
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: 10px 0;

    .avatar-col {
      flex: 1;
    }

    .middle-col {
      flex: 5;
      padding-left: 15px;
      font-size: 14px;
      text-align: left;
    }
  }
}

.flow.removeBg:before {
  background: none !important;
}
</style>
